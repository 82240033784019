<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <list-page
      :columns="columns"
      :headers="headers"
      :popupComponent="popupComponent"
      :basic-data="basicData"
      title="Method List"
      icon="mdi-cash-register"
      addButtonText="Method"
      stateendpoint="method.methods"
      :disable-pagination="true"
      :disable-default-footer="true"
      scrollable="method"
      sort="sortId"
      :descending="false"
      :actions="actions"
  />
</template>
<script>
import ListPage from "../components/ListPage";
import MethodPopup from "../components/popups/MethodPopup";

export default {
  name: 'Methods',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Methods | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, MethodPopup},
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "sortId",
          sortable: false
        },
        {
          text: "Avatar",
          value: "attachment",
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          sortable: false
        },
        {
          text: "Component",
          value: "component",
          sortable: false
        },
        {
          text: "Description",
          value: "description",
          sortable: false
        },
        {
          text: "Status",
          value: "status",
          sortable: false
        },
        {
          text: "Is Default?",
          value: "isByDefault",
          sortable: false
        },
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ],
      columns: [
        {value: "name"},
        {value: "isActive"},
        {value: "action"},
      ],
      actions: {
        load: "method/load",
        create: "method/store",
        update: "method/update",
        remove: "method/remove",
        destroy: "method/destroy"
      },
      basicData: {
        isActive: true,
        isByDefault: false,
      }
    }
  },
  computed: {
    popupComponent() {
      return MethodPopup;
    }
  }
}
</script>
